import React, { useEffect, useState } from 'react';
import { Typography, Table } from 'antd';
import Proptypes from 'prop-types';
import CustomIcon from '../CustomIcon/CustomIcon';

import './index.less';

const { Title } = Typography;

const DetailDownloadModal = ({ idTracking, files, onCancel }) => {
  const [items, setItems] = useState([]);

  const refactorUrl = (file) => {
    return file.map((e) => {
      return { fileName: e };
    });
  };

  useEffect(() => {
    if (files) {
      setItems(refactorUrl(files));
    }
  }, [files]);

  const columns = [
    {
      title: 'Nombre de Archivo',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (fileName) => {
        const indexEnd = fileName.indexOf('?AWSAccessKe');
        const indexStart = fileName.indexOf(idTracking);
        const text = decodeURI(fileName.slice(indexStart + idTracking.length + 1, indexEnd));
        return (
          <div className="row-download" onClick={() => window.open(fileName)}>
            <span>
              <CustomIcon type="download2" /> {text}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <CustomIcon className="cancel-sendEmailModal" type="cancel" onClick={() => onCancel()} />

      <div className="container-downloadModal">
        <Title className="title-downloadModal">Archivos adjuntos</Title>
        <Table
          columns={columns}
          dataSource={items}
          size="middle"
          scroll={{ y: 'calc(320px)' }}
          className="table-download"
          pagination={false}
        />
      </div>
    </>
  );
};

DetailDownloadModal.displayName = 'DetailDownloadModal';

DetailDownloadModal.defaultProps = {
  files: [],
  onCancel: () => {},
};

DetailDownloadModal.propTypes = {
  files: Proptypes.array,
  onCancel: Proptypes.func,
};

export default DetailDownloadModal;
