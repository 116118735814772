/* eslint-disable prettier/prettier */
// import Amplify, { AWSS3 } from 'aws-amplify';
import AWS from 'aws-sdk';

// AWS.configure({
//     AWS: {
//       region: process.env.REACT_REGION,
//       bucket: process.env.REACT_APP_S3_BUCKET,
//   },
// });

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
});

const AWSS3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_S3_BUCKET },
      region: process.env.REACT_APP_REGION,
});
  

export default AWSS3;