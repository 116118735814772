import React, { useEffect, useState } from 'react';
import './index.less';
import { Row, Col, Divider } from 'antd';
import HeadTable from '../HeadTable/HeadTable';
import CustomIcon from '../CustomIcon/CustomIcon';
import { formatDate, orderArray } from '../../utilities/Functions';

const TrackingModal = ({
  onCancel,
  onSearchInfo, //  show info to search
  fileTracking,
  showDownloadModal,
}) => {
  const [trackingOrder, setTrackingOrder] = useState([]);
  const headTracking = [
    {
      title: 'Estado TL',
      width: 3,
    },
    {
      title: 'Responsable - Área',
      width: 4,
    },
    {
      title: 'Acción',
      width: 3,
    },
    {
      title: 'Fecha',
      width: 3,
    },
    {
      title: 'Canal',
      width: 3,
    },
    {
      title: 'Adj',
      width: 1,
    },
    {
      title: 'Comentarios',
      width: 7,
    },
  ];
  const iconTracking = (order) => {
    let path;
    switch (order) {
      case 1:
        path = `rocket`;
        break;
      case 2:
        path = `arrow`;
        break;
      case 3:
        path = `check2`;
        break;
      case 4:
        path = `lock`;
        break;
      default:
        path = `rocket`;
        break;
    }
    return path;
  };

  useEffect(() => {
    if (fileTracking) {
      const initArray = fileTracking.filter((e) => e.Order === 1);
      const endArray = orderArray(
        fileTracking.filter((e) => e.Order === 2 || e.Order === 3 || e.Order === 4),
        'Responsible'
      );

      setTrackingOrder([...initArray, ...endArray]);
    }
  }, [fileTracking]);

  return (
    <div>
      <CustomIcon className="cancel-trackingModal" type="cancel" onClick={() => onCancel()} />
      <div
        className="newAction-trackingModal"
        onClick={() => {
          onSearchInfo(true);
        }}
      >
        +
      </div>
      <HeadTable head={headTracking} className="headTable-TrackingModal" />
      {trackingOrder?.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>
            <Row className="row-trackingModal">
              <Col span={3} className="column-trackingModal">
                <CustomIcon type={iconTracking(item.Order)} className="icon-trackingModal" />
                {item.Name}
              </Col>
              <Col span={4} className="column-trackingModal">
                {item.Responsible}
              </Col>
              <Col span={3} className="column-trackingModal">
                {item.Action}
              </Col>
              <Col span={3} className="column-trackingModal">
                {item.Date ? formatDate(item.Date) : ''}
              </Col>
              <Col span={3} className="column-trackingModal">
                {item.Channel}
              </Col>
              <Col span={1} className="column-trackingModal">
                {item.AttachmentFile?.length > 0 && (
                  <CustomIcon
                    type="clip"
                    className="button-trackingModal"
                    onClick={() => showDownloadModal(true, item.AttachmentFileUrl)}
                  />
                )}
              </Col>
              <Col span={7} className="column-trackingModal">
                {item.Comments}
              </Col>
            </Row>
            <Divider className="divider-trackingModal" />
          </div>
        );
      })}
    </div>
  );
};

export default TrackingModal;
