/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import './index.less';
import { Typography, Modal, Tooltip, DatePicker, Table } from 'antd';
import {
  TitlePage,
  CustomIcon,
  TrackingModal,
  NewActionModal,
  ResultModal,
  Loading,
  SendEmailModal,
  SentCommunDetail,
} from '../../components/index';
import { pathServer } from '../../utilities/Constant';
import { CurrentEmail } from '../../../data/entities/CurrentEmail';
import { showNotification } from '../../utilities/Notification';
import DetailDownloadModal from '../../components/DetailDownload/DetailDownloadModal';

const { Title } = Typography;
const dateFormat = 'DD/MM/YYYY';
const TrackingTemplate = ({
  loading,
  showTrackingModal,
  trackingModal,
  onShowNewAction,
  modalNewAction,
  onShowSaveAction,
  modalSaveAction,
  onChangeFilter,
  trackingFileFilter,
  onGetTracking,
  onConfirmModal,
  tracking,
  onChangeSelected,
  itemSelected,
  loadingSaveAction,
  onChangeLoadingSaveAction,
  showListEmailModal,
  onListEmailModal,
  showViewEmailModal,
  onViewEmailModal,
  showEditEmailModal,
  onEditEmailModal,
  onSendEmail,
  onUpdateEmail,
  showNotificationType,
  onNotificationType,
  onSendEmailModal,
  showSendEmailModal,
  onErrorValidationModal,
  onConfirmUpdateEmailModal,
  showConfirmUpdateEmailModal,
  showUpdateEmailModal,
  onUpdateEmailModal,
  loadingUpdateEmail,
  onDownloadModal,
  showDownloadModal,
}) => {
  const [fileTracking, setFileTracking] = useState([]);
  const [idTracking, setIdTracking] = useState('');
  const [fileName, setFileName] = useState('');
  const [listResponsible, setListResponsible] = useState([]);
  const [currentEmail, setCurrentEmail] = useState({});
  const [filesDownload, setFilesDownload] = useState([]);
  const columns = [
    {
      title: 'Nombre de Archivo',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (fileNameProp) => (
        <div className="column-actionsTracking">
          <span className="icon-fileTracking">PP</span> {fileNameProp}
        </div>
      ),
    },
    {
      title: 'Fecha subida',
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: 150,
      render: (creationDate) => (
        <div className="column-actionsTracking">
          <CustomIcon type="calendar" /> {creationDate}
        </div>
      ),
    },
    {
      title: 'Peso',
      dataIndex: 'size',
      key: 'size',
      width: 110,
    },
    {
      title: 'Periodo',
      dataIndex: 'period',
      key: 'period',
      width: 120,
      render: (a) => <span>{a.Type}</span>,
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 140,
    },
    {
      title: 'Validación',
      key: 'analyzeValidation',
      dataIndex: 'analyzeValidation',
      width: 100,

      render: (item) => (
        <div className="column-validation">
          <div
            className={`${
              Number.isInteger(item.Positive) && item.Positive !== 0 ? '' : 'hide-button'
            }`}
          >
            <CustomIcon type="check" />
            <span className="value-validateTracking">{item.Positive}</span>
          </div>
          <div
            className={`${
              Number.isInteger(item.Negative) && item.Negative !== 0 ? '' : 'hide-button'
            }`}
          >
            <CustomIcon className="icon-validateTracking" type="cross" />
            <span>{item.Negative}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Acciones',
      key: 'analyzeValidation',
      dataIndex: 'analyzeValidation',
      width: 220,
      render: (validation, row) => {
        const disabledAdjuntos = row.attachmentFile;
        return (
          <div className="column-actionsTracking">
            <Tooltip
              className={`${!row.error || row.trackingEmail.length === 0 ? 'hide-button' : ''}`}
              placement="bottom"
              title="Ver E-mail enviado"
              onClick={() => {
                onChangeSelected(row);
                showListEmailModal(true);
              }}
            >
              <CustomIcon type="seeEmailTracking" className="icon-actionTracking" />
            </Tooltip>
            <Tooltip placement="bottom" title="Descargar archivo con análisis">
              <a href={row.analyze?.url} target="_blank" rel="noreferrer">
                <img
                  src={`${pathServer.PATH_IMG}ic_excelTracking.png`}
                  style={{
                    width: '36px',
                    height: '36px',
                    cursor: 'pointer',
                  }}
                />
              </a>
            </Tooltip>
            <Tooltip
              className={`${!row.error ? 'hide-button' : ''}`}
              placement="bottom"
              title={`${disabledAdjuntos ? 'Descargar adjuntos' : ''}`}
            >
              <a
                onClick={() => downloadFiles(row)}
                target="_blank"
                rel="noreferrer"
                className={`${!disabledAdjuntos && 'icon-disabled'}`}
              >
                <CustomIcon
                  type="downloadAttachments"
                  className={`icon-actionTracking ${!disabledAdjuntos && 'icon-disabled'}`}
                />
              </a>
            </Tooltip>
            <Tooltip
              className={`${!row.error ? 'hide-button' : ''}`}
              placement="bottom"
              title="Ver tracking"
            >
              <CustomIcon
                type="seeTracking"
                className="icon-actionTracking"
                onClick={() => {
                  onChangeSelected(row);
                  showTrackingModal(true);
                }}
              />
            </Tooltip>

            <Tooltip
              className={`${!row.error ? 'hide-button' : ''}`}
              placement="bottom"
              title="Archivo con error en validación"
              overlayClassName="invalid"
            >
              <CustomIcon type="alert" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const downloadFiles = (row) => {
    const filesAll = [];
    showDownloadModal(true);
    setIdTracking(row.id);

    const filesTracking = row.tracking.filter((e) => e.Order === 3 || e.Order === 4);
    filesTracking?.forEach((e) => {
      if (e.AttachmentFileUrl?.length > 0) filesAll.push(...e.AttachmentFileUrl);
    });
    setFilesDownload(filesAll);
  };
  const onCloseNotification = () => {
    showNotificationType(false);
  };
  const handleChange = (email) => {
    setCurrentEmail({ ...currentEmail, ...email });
  };

  useEffect(() => {
    if (itemSelected) {
      setFileName(itemSelected.fileName);
      setFileTracking(itemSelected.tracking);
      setIdTracking(itemSelected.id);
      const listEncargados = itemSelected.tracking
        .filter((e) => e.Order === 2)
        .map(
          ({
            Emails: {
              To: { Name },
            },
          }) => {
            return { label: Name, value: Name };
          }
        );
      setListResponsible(listEncargados);
    }
  }, [itemSelected]);

  return (
    <div>
      {showNotification(onNotificationType, onCloseNotification)}
      <TitlePage
        title="Seguimiento"
        secondTitle="Refrescar"
        note="*Se muestran los resultados de los archivos analizados"
        onAction={onGetTracking}
      />
      <div className="title-tracking">
        <Title level={5} className="secondTitle-tracking">
          Archivos analizados
        </Title>
        <DatePicker
          onChange={(date, dateString) => {
            onChangeFilter(dateString);
          }}
          className="datePicker-tracking"
          placeholder="Filtrar por fecha"
          format={dateFormat}
        />
      </div>
      <Table
        columns={columns}
        dataSource={trackingFileFilter}
        size="middle"
        scroll={{ x: 'calc(700px)' }}
        className="table-tracking"
      />
      <Modal className="hide-close-modal" visible={onErrorValidationModal.value} footer={null}>
        <ResultModal
          icon="error"
          title={onErrorValidationModal.title}
          subtitle={onErrorValidationModal.subtitle}
          button={onErrorValidationModal.nameButton}
          buttonActionFirst={onErrorValidationModal.functionError}
        />
      </Modal>

      <Modal
        visible={trackingModal}
        className="modal-SendEmail hide-close-modal"
        footer={null}
        zIndex={1000}
      >
        <TrackingModal
          onCancel={() => {
            showTrackingModal(false);
          }}
          onSearchInfo={onShowNewAction}
          fileTracking={fileTracking}
          showDownloadModal={(showModal, listDownload) => {
            showDownloadModal(showModal);
            setFilesDownload(listDownload);
          }}
        />
      </Modal>
      <Modal visible={modalNewAction} className="modal-SendEmail hide-close-modal" footer={null}>
        <NewActionModal
          onCancel={() => {
            onShowNewAction(false);
          }}
          onAddAction={(value, files, currentTracking) =>
            onShowSaveAction(value, files, idTracking, currentTracking)
          }
          modalSaveAction={modalSaveAction}
          tracking={tracking}
          fileName={fileName}
          listResponsible={listResponsible}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={modalSaveAction} footer={null}>
        <ResultModal
          twoButtons
          icon="question"
          title="¿Estás seguro de guardar la acción?"
          subtitle="Ten en cuenta que las acciones guardadas NO podrán ser revertidas"
          buttonActionSecond={() => {
            onConfirmModal(true);
            onChangeLoadingSaveAction(true);
          }}
          buttonActionThird={() => {
            onConfirmModal(false);
          }}
          loading={loadingSaveAction}
        />
      </Modal>
      <Modal className="modal-SendEmail hide-close-modal" visible={onListEmailModal} footer={null}>
        <SendEmailModal
          files={itemSelected}
          onCancel={() => showListEmailModal(false)}
          onViewEmail={(emails) => {
            if (emails) {
              showViewEmailModal(true);
              setCurrentEmail(new CurrentEmail(emails));
            }
          }}
          onEditEmail={(emails) => {
            if (emails) {
              setCurrentEmail(new CurrentEmail(emails));
              showEditEmailModal(true);
              showNotificationType('editableFields');
              setTimeout(() => {
                showNotificationType(false);
              }, 4500);
            }
          }}
          onSendEmail={(emails) => onSendEmail(emails.id, emails.To?.Name)}
          loadingSend={loading}
        />
      </Modal>
      <Modal visible={onViewEmailModal} className="modal-SendEmail hide-close-modal" footer={null}>
        <SentCommunDetail currentEmail={currentEmail} onCancel={() => showViewEmailModal(false)} />
      </Modal>
      <Modal visible={onEditEmailModal} className="modal-SendEmail hide-close-modal" footer={null}>
        <SentCommunDetail
          currentEmail={currentEmail}
          handleChange={handleChange}
          onCancel={() => showConfirmUpdateEmailModal(true)}
          isEditable
        />
      </Modal>
      <Modal visible={onDownloadModal} className="hide-close-modal" footer={null} zIndex={1001}>
        <DetailDownloadModal
          idTracking={idTracking}
          files={filesDownload}
          onCancel={() => showDownloadModal(false)}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onConfirmUpdateEmailModal} footer={null}>
        <ResultModal
          twoButtons
          icon="question"
          title="¿Desea guardar los cambios realizados?"
          subtitle="Confirmar si desea guardar las modificaciones en el correo"
          buttonActionSecond={() => onUpdateEmail(currentEmail)}
          buttonActionThird={() => {
            showConfirmUpdateEmailModal(false);
            showEditEmailModal(false);
          }}
          loading={loadingUpdateEmail}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onUpdateEmailModal} footer={null}>
        <ResultModal
          icon="successfull"
          title="El E-mail fue actualizado satisfactoriamente"
          button="Aceptar"
          buttonActionFirst={() => {
            showUpdateEmailModal(false);
            showEditEmailModal(false);
          }}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onSendEmailModal} footer={null}>
        <ResultModal
          icon="successfull"
          title="E-mail enviado satisfactoriamente"
          subtitle="Se envió el E-mail a los responsables de manera correcta" // el # de Envio dinamico
          button="Aceptar"
          buttonActionFirst={() => {
            showSendEmailModal(false);
          }}
        />
      </Modal>
      {loading && <Loading />}
    </div>
  );
};

export default TrackingTemplate;
