import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';

import {
  TitlePage,
  DropZone,
  ResultModal,
  SendEmailModal,
  SentCommunDetail,
  Loading,
} from '../../components/index';
import { formatUpdateEmail } from '../../utilities/Functions';

import PSLoadingFiles from './PSLoadingFiles/PSLoadingFiles';
import PSValidatingFiles from './PSValidatingFiles/PSValidatingFiles';
import PSValidationResults from './PSValidationResults/PSValidatingFiles';
import { CurrentEmail } from '../../../data/entities/CurrentEmail';
import { showNotification } from '../../utilities/Notification';

import './index.less';

const FileUploadTemplate = ({
  onListEmailModal,
  showListEmailModal,
  onSuccessModal,
  showSuccessModal,
  loadingFiles,
  showLoadingNotificacion,
  onUploadFile,
  onAnalizeFile,
  onErrorValidationModal,
  showErrorValidationModal,
  showViewEmailModal,
  onViewEmailModal,
  showEditEmailModal,
  onEditEmailModal,
  showSendEmailModal,
  onSendEmailModal,
  onConfirmUpdateEmailModal,
  showConfirmUpdateEmailModal,
  showUpdateEmailModal,
  onUpdateEmailModal,
  onGetResultAnalized,
  head,
  head2,
  failedhead,
  files,
  resultFailed,
  options,
  currentPeriod, // mensual trimeestral....
  onChangePeriod,
  currentYear,
  onChangeCurrentYear,
  currentOrder,
  onChangeOrder,
  onUpdateEmail,
  onSendEmail,
  showConfirmRemoveFile,
  onRemoveFile,
  onErrorFormatFile,
  showErrorFormatFile,
  currentTimeStamp,
  clearDataUpload,
}) => {
  const showItemsLoading = (file) => {
    const name = file.name.split('.');
    const fileType = name[name.length - 1];
    if (['xls', 'xlsx'].includes(fileType)) {
      onUploadFile(file);
      setNotificationType('uploadingFiles');
      setShowPage('loadingFiles');
      showLoadingNotificacion(true);
      setTimeout(() => {
        setNotificationType('uploadSuccess');
        showLoadingNotificacion(false);
      }, 15000);
    } else {
      showErrorValidationModal({
        value: true,
        title: 'Tipo de archivo no permitido',
        subtitle: 'Solo se admite archivos .xls y .xlsx',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
  };
  const [showPage, setShowPage] = useState('initial');
  const [resultAnalized, setResultAnalized] = useState([]);
  const [notificationType, setNotificationType] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [loadingUpdateEmail, setLoadingUpdateEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowAnalyzed, setRowAnalyzed] = useState({});
  const [currentEmail, setCurrentEmail] = useState({});

  useEffect(() => {
    if (notificationType === 'uploadSuccess') {
      setTimeout(() => {
        onCloseNotification();
      }, 6000);
    }
  }, [notificationType]);

  useEffect(() => {
    if (onErrorFormatFile) {
      showErrorValidationModal({
        value: true,
        title: 'Archivo no permitido',
        subtitle: 'El archivo subido no tiene la estructura esperada',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
          showErrorFormatFile(false);
          setShowPage('initial');
          setNotificationType(false);
        },
      });
    }
  }, [onErrorFormatFile]);

  const closeUpdateEmail = async () => {
    setLoadingUpdateEmail(true);
    const newFormat = formatUpdateEmail(currentEmail);
    const [data, error] = await onUpdateEmail(currentEmail.id, currentEmail.toName, newFormat);
    setLoadingUpdateEmail(false);
    if (data && error === null) {
      showUpdateEmailModal(true);
      showConfirmUpdateEmailModal(false);
      setRowAnalyzed(data);
      const index = resultAnalized.findIndex((item) => item.id === data.id);
      resultAnalized[index] = data;
      setResultAnalized([...resultAnalized]);
    } else {
      showEditEmailModal(false);
      showConfirmUpdateEmailModal(false);
      showErrorValidationModal({
        value: true,
        title: 'Proceso de Actualización con Errores',
        subtitle: 'Se encontraron problemas al hacer la actualización del correo',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
  };

  const handleChange = (email) => {
    setCurrentEmail({ ...currentEmail, ...email });
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const onCloseNotification = () => {
    setNotificationType(false);
  };

  const validateFiles = async () => {
    setNotificationType('waitValidation');
    setLoadingValidate(true);
    scrollToTop();
    const [data, error] = await onAnalizeFile(currentTimeStamp);
    setNotificationType(false);
    setLoadingValidate(false);
    if (data && error === null) {
      showSuccessModal(true);
      setResultAnalized(data);
    } else {
      showErrorValidationModal({
        value: true,
        title: 'Proceso de Análisis con Errores',
        subtitle: 'En el archivo que se cargo se encontraron diferencias',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
          setShowPage('initial');
        },
      });
    }
  };

  const onUpdateResultAnalized = async () => {
    // loading   .... ; // todo el order es de acuerdo al tipo de periodo
    setLoading(true);
    const [data, error] = await onGetResultAnalized(currentTimeStamp);
    if (data && error === null) {
      setResultAnalized([...data]);
    }
    setLoading(false);
  };

  const renderCurrentContent = (value) => {
    switch (value) {
      case 'initial':
        return (
          <>
            <TitlePage title="Subir archivos" />
            <DropZone onSelectFile={showItemsLoading} />
            {/* <div className="container-bn-footer">
              <Button className="bn-analysis">Iniciar el análisis de archivos</Button>
              <span className="text-footer">o</span>
              <span className="link-delete">Borrar archivos</span>
            </div> */}
          </>
        );
      case 'loadingFiles':
        return (
          <>
            <TitlePage title="Subir archivos" />
            <PSLoadingFiles
              head={head}
              head2={head2}
              files={files}
              loadingFiles={loadingFiles}
              validateFiles={validateFiles}
              currentPeriod={currentPeriod}
              onChangePeriod={onChangePeriod}
              currentYear={currentYear}
              onChangeCurrentYear={onChangeCurrentYear}
              currentOrder={currentOrder}
              onChangeOrder={onChangeOrder}
              optionsPeriod={options}
              removeFile={() => showConfirmRemoveFile(true)}
            />
          </>
        );
      case 'validationFiles':
        return (
          <PSValidatingFiles
            failedhead={failedhead}
            head2={head2}
            files={files}
            validateFiles={validateFiles}
          />
        );
      case 'validationResults':
        return (
          <PSValidationResults
            resultFailed={resultFailed}
            files={resultAnalized}
            options={options}
            showSendEmailModal={(row) => {
              if (row.trackingEmail) {
                setRowAnalyzed(row);
                showListEmailModal(true);
              }
            }}
            onUpdateResultAnalized={onUpdateResultAnalized}
          />
        );
      default:
        return (
          <div>
            <span>Opcion invalida</span>
          </div>
        );
    }
  };

  const sendEmail = async (emailSelected) => {
    setNotificationType('sendingEmail');
    setLoading(true);
    setLoadingSendEmail(true);
    const [data, error] = await onSendEmail(emailSelected.id, emailSelected.To?.Name);
    setLoadingSendEmail(false);
    setNotificationType(false);
    setLoading(false);

    if (data && error === null) {
      showSendEmailModal(true);
      const index = rowAnalyzed.trackingEmail.findIndex(
        (e) => e.Emails.To.Name === emailSelected.To?.Name
      );
      rowAnalyzed.trackingEmail[index].Emails = data;
      setRowAnalyzed({ ...rowAnalyzed });
      const indexGeneral = resultAnalized.findIndex((item) => item.id === emailSelected.id);
      resultAnalized[indexGeneral].trackingEmail = rowAnalyzed.trackingEmail;
      setResultAnalized(resultAnalized);
    } else {
      showErrorValidationModal({
        value: true,
        title: 'Proceso de Envío de correo con Errores',
        subtitle: 'Se presentaron inconvenientes al enviar el correo',
        nameButton: 'Cerrar',
        functionError: () => {
          showErrorValidationModal({ value: false });
        },
      });
    }
  };

  useEffect(() => {
    renderCurrentContent(showPage);
  }, [showPage]);

  return (
    <>
      {showNotification(notificationType, onCloseNotification)}
      <div className={`${loadingValidate ? 'opacity' : ''} `}>{renderCurrentContent(showPage)}</div>
      <Modal className="hide-close-modal" visible={onSuccessModal} footer={null}>
        <ResultModal
          icon="successfull"
          title="Proceso de Análisis Satisfactorio"
          subtitle="Se mandaron a analizar los archivos, a continuación visualizarás los resultados finalizados"
          button="Ver resultados"
          buttonActionFirst={() => {
            showSuccessModal(false);
            setShowPage('validationResults');
          }}
        />
      </Modal>

      <Modal className="hide-close-modal" visible={onErrorValidationModal.value} footer={null}>
        <ResultModal
          icon="error"
          title={onErrorValidationModal.title}
          subtitle={onErrorValidationModal.subtitle}
          button={onErrorValidationModal.nameButton}
          buttonActionFirst={onErrorValidationModal.functionError}
        />
      </Modal>

      <Modal className="hide-close-modal" visible={onRemoveFile} footer={null}>
        <ResultModal
          twoButtons
          icon="question"
          title="¿Estás seguro que desea borrar el archivo subido?"
          buttonActionSecond={() => {
            setShowPage('initial');
            clearDataUpload();
          }}
          buttonActionThird={() => {
            showConfirmRemoveFile(false);
          }}
        />
      </Modal>

      <Modal className="modal-SendEmail hide-close-modal" visible={onListEmailModal} footer={null}>
        <SendEmailModal
          files={rowAnalyzed}
          onCancel={() => showListEmailModal(false)}
          onViewEmail={(emails) => {
            if (emails) {
              showViewEmailModal(true);
              setCurrentEmail(new CurrentEmail(emails));
            }
          }}
          onEditEmail={(emails) => {
            if (emails) {
              setCurrentEmail(new CurrentEmail(emails));
              showEditEmailModal(true);
              setNotificationType('editableFields');
              setTimeout(() => {
                setNotificationType(false);
              }, 4500);
            }
          }}
          onSendEmail={(emails) => sendEmail(emails)}
          loadingSend={loadingSendEmail}
        />
      </Modal>

      <Modal visible={onViewEmailModal} className="modal-SendEmail hide-close-modal" footer={null}>
        <SentCommunDetail currentEmail={currentEmail} onCancel={() => showViewEmailModal(false)} />
      </Modal>
      <Modal visible={onEditEmailModal} className="modal-SendEmail hide-close-modal" footer={null}>
        <SentCommunDetail
          currentEmail={currentEmail}
          handleChange={handleChange}
          onCancel={() => showConfirmUpdateEmailModal(true)}
          isEditable
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onConfirmUpdateEmailModal} footer={null}>
        <ResultModal
          twoButtons
          icon="question"
          title="¿Desea guardar los cambios realizados?"
          subtitle="Confirmar si desea guardar las modificaciones en el correo"
          buttonActionSecond={closeUpdateEmail}
          buttonActionThird={() => {
            showConfirmUpdateEmailModal(false);
            showEditEmailModal(false);
          }}
          loading={loadingUpdateEmail}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onUpdateEmailModal} footer={null}>
        <ResultModal
          icon="successfull"
          title="El E-mail fue actualizado satisfactoriamente"
          button="Aceptar"
          buttonActionFirst={() => {
            showUpdateEmailModal(false);
            showEditEmailModal(false);
          }}
        />
      </Modal>
      <Modal className="hide-close-modal" visible={onSendEmailModal} footer={null}>
        <ResultModal
          icon="successfull"
          title="E-mail enviado satisfactoriamente"
          subtitle="Se envió el E-mail a los responsables de manera correcta" // el # de Envio dinamico
          button="Aceptar"
          buttonActionFirst={() => {
            showSendEmailModal(false);
          }}
        />
      </Modal>
      {loading && <Loading />}
    </>
  );
};
export default FileUploadTemplate;
